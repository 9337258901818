import React, { useState } from "react";
import VerticalMenu from "../../../components/VerticalMenu";
import HorizontalMenu from "../../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import CodeContainer from "../../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../../images/favicon.ico";
import JsonContainer from "../../../components/JsonContainer";
import {
  codeFont,
  listStyle,
  listItems,
  pageStyles,
  pageContent,
} from "../../../styleConstants";
import PageTitle from "../../../components/PageComponents/PageTitle";
import Paragraph from "../../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../../components/PageComponents/ParagraphTitle";
import {
  dataLibraryEndpoints,
  dataLibraryListJson,
  dataLibraryResponseJson,
} from "../../../constants";
import SupportedMapTypes from "../../../components/SupportedMaptypes";
import VersionSwitch from "../../../components/VersionSwitch";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={11} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="List all purchased data library datasets" />
          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />
          <Paragraph text="You can get a list of all your purchased datasets from the data library that were purchased by the same user and the same API key." />
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/datalibraryorders" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/datalibraryorders" />
          )}
          <Paragraph
            text="The List Orders endpoint supports filtering on data delivery product and specifying the page and the limit with the
            following query parameters. The listing endpoints can include the following:"
          />
          <ul style={listStyle}>
            {dataLibraryEndpoints.map(({ code, description }) => (
              <li key={code} style={listItems}>
                <code style={codeFont}>{code}</code> : {description}
              </li>
            ))}
          </ul>
          <br />
          <br />
          <SupportedMapTypes />
          <ParagraphTitle text="Example Request" />
          {index === 0 ? (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v1/datalibraryorders" />
          ) : (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v2/datalibraryorders" />
          )}
          <JsonContainer text={dataLibraryListJson} />
          <ParagraphTitle text="Example response" />
          <JsonContainer text={dataLibraryResponseJson} />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
